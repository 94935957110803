import React from "react";

import styles from "./Tarrif.module.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function BikeCard({
  _id,
  bike_name,
  bike_image,
  hourly_rate,
  full_day_rate,
  full_day_limit,
  half_day_rate,
  half_day_limit,
  excess_kms_charge,
  kilometer_limit,
  bookNowHandler,
}) {
  return (
    <div className={styles.BikeCard}>
      <h2>{bike_name}</h2>
      <div className={styles.BikeCard_image}>
        <img src={bike_image} alt="bikeImage"></img>
      </div>
      <Tabs>
        <div>
          <TabList className={styles.BikeCard__Nav}>
            <Tab className={styles.BikeCard__Nav__tab}>Daily</Tab>
          </TabList>
          <TabPanel>
            <div className={styles.BikeCard__panel}>
              <div className={styles.BikeCard__panel__sub}>
                <b>Mon-Sun</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Full day</span>
                </div>
                <div>
                  <span>₹ {full_day_rate}/day</span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Half day</span>
                </div>
                <div>
                  <span>₹ {half_day_rate}/day</span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__sub}>
                <b>Extras</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Kilometers Limit (Full day)</span>
                </div>
                <div>
                  <span>{full_day_limit}/day</span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Kilometers Limit (Half day)</span>
                </div>
                <div>
                  <span>{half_day_limit}/day</span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Excess Kilometer Charges</span>
                </div>
                <div>
                  <span>₹ {excess_kms_charge}/km</span>
                </div>
              </div>
              <div style={{ paddingTop: ' 0.8rem' }}>
                <div style={{ fontSize: ' 0.7rem' }}>
                  <ul style={{lineHeight: '14px'}}  > 
                  <li> A day is calculated as 09:30 am to next day 09:00 am.</li>
                  <li>Half a day is either from  09:30 am to same day 08:30 pm or 09:00 pm to next day 09:00 am</li>
                  <li>Min of 1 days billing cycle</li>
                  </ul> 
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </Tabs >
      {/* <Tabs>
        <div>
          <TabList className={styles.BikeCard__Nav}>
            <Tab className={styles.BikeCard__Nav__tab}>Hourly</Tab>
            <Tab className={styles.BikeCard__Nav__tab}>7 Days</Tab>
            <Tab className={styles.BikeCard__Nav__tab}>15 Days</Tab>
            <Tab className={styles.BikeCard__Nav__tab}>30 Days</Tab>
            <Tab className={styles.BikeCard__Nav__tab}>90 Days</Tab>
          </TabList>
          <TabPanel>
            <div className={styles.BikeCard__panel}>
              <div className={styles.BikeCard__panel__sub}>
                <b>Mon-Thu</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Booking Time(0 - 12 hrs)</span>
                </div>
                <div>
                  <span>₹ {hourly_rate}/hr</span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Booking Time({`>`} 12 hrs)</span>
                </div>
                <div>
                  <span>₹ {hourly_rate - 5}/hr</span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__sub}>
                <b>Extras</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Kilometers Limit</span>
                </div>
                <div>
                  <span>{kilometer_limit}/hr</span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Excess Kilometer Charges</span>
                </div>
                <div>
                  <span>₹ {kilometer_limit - 1}/km</span>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className={styles.BikeCard__panel}>
              <div className={styles.BikeCard__panel__sub}>
                <b>Rental Charges</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Price for 7 days</span>
                </div>
                <div>
                  <span>₹ {hourly_rate * 7 * 24} </span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__sub}>
                <b>Km Limit</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>
                    No of kms in 7 days that can be travelled without extra
                    charges
                  </span>
                </div>
                <div>
                  <span>{kilometer_limit * 100} Km</span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__sub}>
                <b>Excess Kilometer Charges</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Excess Kilometer Charges</span>
                </div>
                <div>
                  <span>₹ {kilometer_limit - 2}/km</span>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className={styles.BikeCard__panel}>
              <div className={styles.BikeCard__panel__sub}>
                <b>Rental Charges</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Price for 15 days</span>
                </div>
                <div>
                  <span>₹ {hourly_rate * 15 * 24} </span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__sub}>
                <b>Km Limit</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>
                    No of kms in 15 days that can be travelled without extra
                    charges
                  </span>
                </div>
                <div>
                  <span>{kilometer_limit * 150} Km</span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__sub}>
                <b>Excess Kilometer Charges</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Excess Kilometer Charges</span>
                </div>
                <div>
                  <span>₹ {kilometer_limit - 2}/km</span>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className={styles.BikeCard__panel}>
              <div className={styles.BikeCard__panel__sub}>
                <b>Rental Charges</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Price for 30 days</span>
                </div>
                <div>
                  <span>₹ {hourly_rate * 17 * 24} </span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__sub}>
                <b>Km Limit</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>
                    No of kms in 30 days that can be travelled without extra
                    charges
                  </span>
                </div>
                <div>
                  <span>{kilometer_limit * 300} Km</span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__sub}>
                <b>Excess Kilometer Charges</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Excess Kilometer Charges</span>
                </div>
                <div>
                  <span>₹ {kilometer_limit - 3}/km</span>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className={styles.BikeCard__panel}>
              <div className={styles.BikeCard__panel__sub}>
                <b>Rental Charges</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Price for 90 days</span>
                </div>
                <div>
                  <span>₹ {hourly_rate * 40 * 24} </span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__sub}>
                <b>Km Limit</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>
                    No of kms in 90 days that can be travelled without extra
                    charges
                  </span>
                </div>
                <div>
                  <span>{kilometer_limit * 900} Km</span>
                </div>
              </div>
              <div className={styles.BikeCard__panel__sub}>
                <b>Excess Kilometer Charges</b>
              </div>
              <div className={styles.BikeCard__panel__flex}>
                <div>
                  <span>Excess Kilometer Charges</span>
                </div>
                <div>
                  <span>₹ {kilometer_limit - 4}/km</span>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </Tabs> */}
      < button
        onClick={() =>
          bookNowHandler({
            _id,
            bike_name,
            bike_image,
            hourly_rate,
            kilometer_limit,
          })
        }
        className={styles.BikeCard__button}
      >
        Rent Now
      </button >
    </div >
  );
}

export default BikeCard;
